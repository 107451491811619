.notfound {
    position: relative;
    width: 100%;
    margin-top: 20px;

    &__title {
        margin: 0;
        color: $colorPrimary;
        font-size: 50px;
        line-height: 1;
        margin-bottom: 12px;
    }

    &__text {
        font-weight: 500;
        margin: 0;

        span {
            font-weight: 600;
            color: $colorPrimary;
        }
    }

    &__buttons {
        @include flex(row, flex-start, flex-start, 8px);
        flex-wrap: wrap;
        margin-top: 20px;
    }
}