.image-loading {
    background: linear-gradient(90deg, darken(white, 30%), darken(white, 10%), darken(white, 30%));
    background-size: 200% 200%;
    animation: gradientAnimation 1s ease infinite alternate;

    @keyframes gradientAnimation {
        0% {
            background-position: 0% 50%;
        } 100% {
            background-position: 100% 50%;
        }
    }
}