.lightbox {
    @include flex(column, center, center);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;

    &__background {
        position: absolute;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(30px);
        height: 100%;
        width: 100%;
        z-index: -1;
    }

    &__body {
        @include flex(column, center, center);
        max-height: 90%;
        width: calc(100% - 20px);
        max-width: 800px;

        &.l,
        &.xl,
        &.xxl {
            width: calc(100% - 60px);
        }

        &__header {
            @include flex(row, center, space-between, 10px);
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(30px);
            color: white;
            padding: 15px;
            border-radius: 10px 10px 0 0;

            &.l,
            &.xl,
            &.xxl {
                width: calc(100% - 60px);
            }

            &__pagination {
                @include flex(row, center, flex-start, 10px);
                font-size: 15px;
                font-weight: bold;
                color: darken(white, 40%);

                &__active {
                    color: white;
                }
            }

            &__close {
                @include flex(row, center, flex-end);
                border: none;
                background: none;
                color: white;
                font-size: 20px;
                padding: 0;
                cursor: pointer;
                transition: all 300ms;

                &:hover {
                    filter: brightness(85%);
                }
            }
        }

        &__pictures {
            overflow-y: auto;
            width: 100%;

            &__picture {
                @include flex(row, center, center);
                width: 100%;
                transition: all 300ms;
    
                &:not(.visible) {
                    display: none;
                }
    
                &__image {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &__footer {
            @include flex(row, center, space-between);
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(30px);
            color: white;
            border-radius: 0 0 10px 10px;

            &__arrow {
                border: none;
                background: none;
                color: white;
                font-size: 20px;
                flex: 1;
                padding: 15px 20px;
                cursor: pointer;
                transition: all 300ms;

                &:hover {
                    filter: brightness(85%);
                }

                &:active {
                    filter: brightness(50%);
                }

                &:first-of-type {
                    @include flex(row, center, flex-start);
                    border-right: 2px solid rgba(255, 255, 255, 0.1);
                }

                &:last-of-type {
                    @include flex(row, center, flex-end);
                }
            }
        }
    }
}