.home {
    &__picture {
        @include flex(row, center, center);
        position: relative;
        overflow: hidden;
        max-height: 80vh;

        &__image {
            width: 100%;
            min-width: 800px;

            &--shop {
                image-orientation: 180deg;
            }

            &--page {
                height: 400px;
                object-fit: cover;
            }

            &--div {
                width: 100%;
                height: 50vh;
                background: linear-gradient(90deg, $colorPrimary, lighten($colorPrimary, 25%), $colorPrimary);
                background-size: 200% 200%;
                animation: gradientAnimation 3s ease infinite alternate;
            }

            @keyframes gradientAnimation {
                0% {
                    background-position: 0% 50%;
                } 100% {
                    background-position: 100% 50%;
                }
            }
        }

        &__wave {
            position: absolute;
            height: 125px;
            width: 100%;
            bottom: -1px;
            transition: all 300ms;

            &.xs {
                height: 80px;
            }

            &__image {
                object-fit: fill;
                height: 100%;
                width: 100%;
            }
        }

        &__button {
            position: absolute;
            top: 15px;
            left: 15px;
            border: none;
            background: $colorPrimary;
            color: white;
            width: 40px;
            height: 40px;
            padding: 10px;
            border-radius: 100px;
            box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            transition: all 300ms;

            &.l {
                top: 20px;
                left: 25px;
            }

            &.xl,
            &.xxl {
                top: 20px;
                left: 30px;
            }

            &:hover {
                filter: brightness(90%);
            }

            &:active {
                filter: brightness(85%);
            }
        }
    }

    &__content {
        @include flex(column, flex-start, flex-start, 125px);
        position: relative;

        &--wave {
            margin-top: -15px;

            &.xs {
                margin-top: 5px;
            }
        }

        &--service {
            margin-top: -40px;

            &.xs {
                margin-top: -15px;
            }
        }

        &--page {
            gap: 100px;
        }

        &__logo {
            width: 125px;
            transition: all 300ms;

            &__bicyclette {
                @include flex(row, center, center);
                height: 125px;
                width: 125px;
                border-radius: 1000px;
                overflow: hidden;
                transition: all 300ms;

                &.l,
                &.xl,
                &.xxl {
                    height: 150px;
                    width: 150px;
                }

                &--bicyclette {
                    width: calc(100% + 8px) !important;
                    transition: all 300ms;
                }
            }

            &.l,
            &.xl,
            &.xxl {
                width: 150px;
            }
        }

        &__title {
            margin-top: 0;
            font-size: 20px;
            line-height: 28px;

            &--generic {
                margin-bottom: 0;
            }
        }

        &__block {
            @include flex(column, flex-start, flex-start, 25px);
            width: 100%;

            &--page {
                transition: all 300ms;

                > .home__content__title {
                    margin-bottom: 0;
                }
            }

            &--carousel {
                width: 100%;
            }

            &--logos {
                margin-bottom: 135px;
            }

            &__flex {
                @include flex(row, center, flex-start, 20px);
                margin-bottom: 40px;

                &--generic {
                    margin-bottom: 0;
                }
            }

            &__header {
                @include flex(row, center, space-between, 10px);
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: -10px;
            }

            &__title {
                position: relative;
                color: $colorPrimary;
                margin: 0 0 -5px 0;
                font-size: 20px;
                line-height: 25px;

                &.xxl {
                    &::after {
                        right: calc(100% + 20px);
                    }
                }

                &,
                &::after {
                    transition: all 300ms;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: -15px;
                    left: 0;
                    right: calc(100% + 10px);
                    background: linear-gradient(45deg, lighten($colorPrimary, 20%), darken($colorPrimary, 10%));
                    height: 3px;
                    width: 100px;
                    border-radius: 100px;
                }

                svg {
                    margin-left: 10px;
                }
            }

            &__text {
                margin: 0;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;

                @media (max-width: 768px) {
                    font-size: 14px;
                }

                span {
                    font-weight: 600;
                    color: $colorPrimary;
                }
            }

            &__info {
                @include flex(column, center, center, 25px);
                position: relative;
                margin-top: calc(250px + 40px);
                width: 100%;
                transition: all 300ms;

                &.l,
                &.xl,
                &.xxl {
                    @include flex(column, center, center, 30px);
                    margin-top: calc(350px + 50px);
                }

                &__title,
                &__text {
                    text-align: center;
                    width: 100%;
                    max-width: 600px;
                }

                &__title {
                    margin: 0;
                    font-size: 20px;
                    line-height: 20px;
                }
            }

            &__slider {
                width: calc(100% + 10px);
                margin: 30px 0 0 -5px;

                .slick-slide > div {
                    padding: 0 5px 10px 5px;
                }

                .slick-arrow {
                    display: none !important;
                }
            }

            &__links {
                @include flex(row, flex-start, flex-start, 5px);
                flex-wrap: wrap;
                transition: all 300ms;
            }
            
            &__gallery {
                @include flex(row, flex-start, flex-start, 5px);
                flex-wrap: wrap;
                width: 100%;

                &__image {
                    @include flex(row, center, center);
                    width: calc(50% - 2.5px);
                    height: 150px;
                    border-radius: 5px;
                    overflow: hidden;
                    cursor: pointer;
                    transition: all 300ms;

                    &.xxl,
                    &.xl,
                    &.l {
                        width: calc(20% - 4px);
                    }

                    &.m {
                        width: calc(25% - 4px);
                    }
    
                    &:hover {
                        filter: brightness(85%);
                    }
    
                    &:active {
                        filter: brightness(70%);
                    }

                    &__img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            &__display {
                @include flex(row, flex-start, space-between, 5px);
                width: 100%;
                margin-top: -20px;

                &__button {
                    flex: 1;
                    border-radius: 5px;

                    &:hover {
                        filter: brightness(95%);
                    }

                    &,
                    &:hover,
                    &:active {
                        padding: 12px 0;
                    }
                }
            }

            &__iframe {
                border-radius: 10px;

                @media (max-width: 560px) {
                    width: 100%;
                }
            }
        }

        &__logos {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            height: 125px;
            width: 100%;
            max-width: 1500px;
            margin-top: 70px;
            overflow: hidden;
            transition: all 300ms;

            &.l,
            &.xl,
            &.xxl {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    z-index: 8;
                    background: linear-gradient(to left, transparent 0%, white 100%);
                    height: 100%;
                    width: 200px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    background: linear-gradient(to right, transparent 0%, white 100%);
                    height: 100%;
                    width: 200px;
                }
            }

            &:hover .home__content__logos__items {
                animation-play-state: paused;
            }

            &__items {
                @include flex(row, flex-start, center, 50px);
                position: absolute;
                left: 0;
                animation: slide 15s linear infinite;

                @keyframes slide {
                    0% {
                        transform: translateX(0);
                    }
    
                    100% {
                        transform: translateX(calc(-25% - 5px));
                    }
                }

                &__item {
                    @include flex(row, center, center);

                    &__image {
                        min-height: 80px;
                        max-height: 80px;
                    }
                }
            }
        }

        &__universes {
            @include flex(column, flex-start, flex-start, 10px);
            width: 100%;
            transition: all 300ms;

            &.m,
            &.l,
            &.xl,
            &.xxl {
                @include flex(row, flex-start, space-between, 10px);
            }

            &__list {
                @include flex(column, flex-start, flex-start, 20px);
                transition: all 300ms;

                &__item {
                    @include flex(column, flex-start, flex-start);
                    border-radius: 15px;
                    background: black;
                    color: white;
                    width: 100%;
                    transition: all 300ms;

                    &:hover {
                        background: darken($colorPrimary, 35%);
                    }

                    &--more {
                        @include flex(row, center, space-between, 10px);
                        background: $colorPrimary;
                        color: white;
                        font-weight: 600;
                        border-radius: 15px;
                        padding: 15px 15px;
                        width: 100%;
                        text-decoration: none;
                        transition: all 300ms;

                        &:hover {
                            background: darken($colorPrimary, 5%);
                        }
    
                        &:active {
                            background: darken($colorPrimary, 10%);
                        }
                    }

                    &__picture {
                        border-radius: 15px 15px 0 0;
                        height: 150px;
                        width: 100%;
                        object-fit: cover;
                        transition: all 300ms;
                    }

                    &__info {
                        @include flex(column, flex-start, flex-start, 15px);
                        position: relative;
                        padding: 20px;
                        border-top: 2px solid $colorPrimary;
                        width: 100%;

                        &__title,
                        &__text {
                            margin: 0;
                        }

                        &__title {
                            font-size: 14px;
                            display: box;
                            display: -webkit-box;
                            line-clamp: 1;
                            box-orient: vertical;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            transition: all 300ms;

                            &--universes {
                                font-size: 16px;
                            }
                        }

                        &__text {
                            line-height: 20px;
                            display: box;
                            display: -webkit-box;
                            margin-bottom: 5px;
                            line-clamp: 2;
                            box-orient: vertical;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            transition: all 300ms;
                        }
                    }
                }
            }
        }
    }
}