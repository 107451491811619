.universes {
    width: 100%;

    &,
    &__search {
        margin-top: 20px;
    }

    &__search {
        width: 100%;
        font-size: 14px;
        margin-bottom: 20px;
        border-radius: 10px;
        border: 1px solid darken(white, 20%);
        padding: 10px 15px;
    }

    &__list {
        @include flex(column, flex-start, flex-start, 10px);
        transition: all 300ms;

        &.m,
        &.l {
            width: 50%;
        }

        &.xl,
        &.xxl {
            width: 33.3%;
        }

        &__item {
            overflow: hidden;
            background: black;
            width: 100%;
            cursor: pointer;
            transition: all 300ms;

            &.m,
            &.l,
            &.xl,
            &.xxl {
                max-height: 322px;

                &:hover {
                    background: darken($colorPrimary, 35%);
                    max-height: 1000px;

                    .home__content__block__text {
                        display: initial;
                        overflow: initial;
                    }
                }
            }

            &__title {
                color: white;
                font-size: 20px;
                line-height: 25px;
                font-weight: 500;
            }

            &__picture {
                border-radius: 15px 15px 0 0;
                height: 200px;
                width: 100%;
                object-fit: cover;
                transition: all 300ms;
            }
        }
    }
}