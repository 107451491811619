.breadcrumb {
    position: fixed;
    top: calc($headerHeight + 3px);
    height: $breadcrumbHeight;
    width: 100%;
    z-index: 99;
    box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.2);

    &__shadow-left,
    &__shadow-right {
        position: fixed;
        top: 0;
        bottom: 0;
        height: $breadcrumbHeight;
        top: calc($headerHeight + 3px);
        width: 50px;
        z-index: 9;
        pointer-events: none;
        transition: all 300ms;

        &:not(.active) {
            opacity: 0;
        }
    }

    &__shadow-left {
        left: 0;
        background: linear-gradient(to left, transparent 0%, black 100%);
    }
      
    &__shadow-right {
        right: 0;
        background: linear-gradient(to right, transparent 0%, black 100%);
    }

    &__scroll {
        @include flex(row, center, flex-start, 12px);
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(30px);
        width: 100%;
        overflow-x: auto;
        transition: all 300ms;

        &.xs,
        &.s,
        &.m {
            padding: 10px 15px;
        }
    
        &.l {
            padding: 10px 25px;
        }
    
        &.xl,
        &.xxl {
            padding: 10px 30px;
        }
    
        &__item {
            position: relative;
            font-weight: 600;
            text-decoration: none;
            white-space: nowrap;
            transition: all 300ms;
    
            &--active {
                color: white;
            }
    
            &:not(.breadcrumb__scroll__item--active) {
                color: lighten($colorPrimary, 10%);
    
                &:hover {
                    color: lighten($colorPrimary, 20%);
                }
            }
        }
    
        svg,
        i {
            color: lighten($colorPrimary, 10%);
            font-size: 10px;
        }
    }
}