.container {
    @include flex(column, center, center);
    position: relative;
    background: white;
    width: 100%;
    padding-bottom: 150px;

    &__content {
        width: 100%;
        max-width: 1140px;
        transition: all 300ms;

        &.xs,
        &.s,
        &.m {
            padding: 0 15px;
        }

        &.l {
            padding: 0 30px;
        }
    }
}