.intro {
    @include flex(column, center, center);
    position: fixed;
    top: calc(-#{$headerHeight} + 3px);
    z-index: 9999999;
    background: black;
    padding: 8px 0;
    height: calc(100vh + $headerHeight + 3px);
    width: 100%;
    transition: all 300ms;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;
        background: linear-gradient(90deg, $colorPrimary, lighten($colorPrimary, 25%), $colorPrimary);
        box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.2);
        background-size: 200% 200%;
        animation: gradientAnimation 3s ease infinite alternate;
    }

    @keyframes gradientAnimation {
        0% {
            background-position: 0% 50%;
        } 100% {
            background-position: 100% 50%;
        }
    }

    &.hidden,
    &:not(.page--home) {
        display: none;
    }

    &.shrink {
        height: calc($headerHeight * 2);
        transform-origin: top;
        transition: height 1.5s 300ms ease-in-out;
    }

    .finished {
        opacity: 0;
    }

    &__container {
        @include flex(column, center, center, 10px);
    }

    &__logo {
        transition: opacity 500ms ease-in-out;
        height: 125px;

        &__img {
            width: 125px;
        }
    }

    &__site {
        font-size: 25px;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin: 0 0 0 3px;
        color: white;
        background: linear-gradient(90deg, $colorPrimary, lighten($colorPrimary, 30%), $colorPrimary);
        background-size: 200%;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        animation: gradientShift 2s ease infinite alternate;
        transition: opacity 500ms ease-in-out;
    }

    &__main {
        position: fixed;
        bottom: 0;
        z-index: -1;
        filter: opacity(0.1);
        transition: opacity 500ms ease-in-out;
    }

    &__bars {
        @include flex(row, center, center, 10px);

        &__item {
            width: 8px;
            height: 40px;
            background-color: white;
            transform-origin: bottom;
            animation: growBar ease-in-out infinite alternate;
        }
    }

    &__button {
        position: fixed;
        bottom: 50px;
        transition: all 300ms, opacity 500ms ease-in-out;
    }

    &__progress {
        position: relative;
        display: inline-block;
        margin: 0 0 0 5px;
        color: lighten(black, 30%);
        transition: opacity 500ms ease-in-out;

        &__background,
        &__foreground {
            font-size: 25px;
            text-transform: uppercase;
            letter-spacing: 5px;
            margin: 0;
        }
      
        &__background {
          position: relative;
          z-index: 1;
        }
      
        &__foreground {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background: linear-gradient(90deg, $colorPrimary, lighten($colorPrimary, 15%), $colorPrimary);
          background-size: 200%;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          overflow: hidden;
          white-space: nowrap;
          transition: width 300ms linear;
          animation: gradientShift 2s ease infinite alternate;
        }
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    } 50% {
        transform: scale(1.1);
    } 100% {
        transform: scale(1);
    }
}

@keyframes gradientShift {
    0% {
        background-position: 0%;
    } 100% {
        background-position: 100%;
    }
}

@keyframes growBar {
    0% {
        transform: scaleY(0.3);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes disappear {
    100% {
        filter: opacity(0);
    }
}