.button {
    border: none;
    font-weight: 600;
    border-radius: 100px;
    text-decoration: none;
    font-size: 12.5px;
    cursor: pointer;
    transition: all 300ms;

    $buttonArrowHeight: 34px;

    &--arrow {
        position: relative;
        background: $colorPrimary;
        color: white;
        padding: 8px 16px 8px 43px;
        transition: all 300ms ease-in-out;

        &:hover {
            padding: 8px 43px 8px 16px;

            .button--arrow__icon {
                left: calc(100% - $buttonArrowHeight);
            }
        }

        &:active {
            background: darken($colorPrimary, 5%);
    
            .button--arrow__icon {
                color: darken($colorPrimary, 5%);
            }
        }

        &__icon {
            @include flex(row, center, center);
            position: absolute;
            top: 0;
            left: 0;
            background: white;
            color: $colorPrimary;
            height: $buttonArrowHeight;
            width: $buttonArrowHeight;
            border-radius: 100px;
            border: 3px solid $colorPrimary;
            font-size: 15px;
            transition: all 500ms ease-in-out;
        }
    }

    &--more {
        @include flex(row, center, center, 8px);
        background: $colorPrimary;
        color: white;
        padding: 8px 16px;
        transition: all 300ms;

        &:hover {
            padding: 8px 23px;
        }
    
        &:active {
            background: darken($colorPrimary, 5%);
        }

        svg {
            font-size: 17px;
        }
    }
}