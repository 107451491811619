.closed {
    background: white;
    padding: 20px;
    margin: 20px;
    width: fit-content;
    border-radius: 10px;
    font-weight: 500;

    hr {
        margin: 15px 0;
        height: 2px;
        border-radius: 100px;
        border: none;
        background: lighten(black, 90%);
    }
}