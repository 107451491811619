.banner {
    @include flex(column, center, center);
    width: calc(100% - 30px);
    max-width: 450px;
    border-radius: 7px;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 20px;
    font-weight: 500;
    backdrop-filter: blur(30px);
    box-shadow: 0px -2px 10px 3px rgba(0, 0, 0, 0.2);

    * {
        text-align: center;
        font-size: 13px;
        line-height: 18px;
    }

    &__title {
        font-size: 15px;
        margin-bottom: 10px;
    }

    &__container {
        @include flex(row, flex-start, center);
        position: fixed;
        bottom: 15px;
        left: 0;
        width: 100%;

        &:before {
            content: "";
            position: fixed;
            width: 100%;
            height: 100px;
            bottom: 0;
            background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
        }
    }

    &__disclaimer {
        &__link {
            color: white;
            transition: all 300ms;

            &:hover {
                filter: brightness(95%);
            }
        }
    }

    &__button {
        @include flex(row, center, center, 5px);
        width: 100%;
        border: none;
        background: none;
        color: white;
        font-weight: 600;
        margin-bottom: 15px;
        cursor: pointer;
        transition: all 300ms;

        &:hover {
            filter: brightness(90%);
        }

        svg,
        i {
            margin-top: -3px;
            font-size: 12px;
        }
    }

    &__link {
        color: white;
        font-weight: 600;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        transition: all 300ms;

        &:hover {
            color: darken(white, 10%);
        }
    }

    &__header {
        @include flex(row, center, space-between, 10px);
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        margin-bottom: 15px;
        padding-bottom: 15px;

        button {
            padding: 0;
            margin: 0;
        }

        &.no-consent {
            @include flex(row, center, center, 10px);

            button {
                @include flex(row, center, center, 7px);
                width: 100%;
            }
        }

        &:not(.no-consent).personalize {
            button {
                &:first-of-type {
                    @include flex(row, center, flex-start, 7px);
                }

                &:last-of-type {
                    @include flex(row, center, flex-end);
                }
            }
        }
    }

    &__disclaimers {
        @include flex(column, flex-start, flex-start, 15px);

        &__disclaimer {
            margin-top: 15px;
        }
    }

    &__buttons {
        @include flex(row, center, flex-start, 7px);
        width: 100%;
        margin-top: 20px;

        &__button {
            width: 100%;
            border: none;
            background: $colorPrimary;
            padding: 7px;
            border-radius: 5px;
            color: white;
            font-weight: 600;
            cursor: pointer;
            transition: all 300ms;

            &--personalize {
                width: fit-content;
                padding: 7px 12px;
            }

            &:hover {
                background: darken($colorPrimary, 5%);
            }

            &.personalize {
                background: white;
                color: black;
                
                &:hover {
                    background: darken(white, 15%);
                }
            }
        }
    }

    &__choice {
        @include flex(column, flex-end, flex-start, 10px);
        margin-top: 25px;
        width: 100%;

        &__item {
            @include flex(row, center, space-between, 10px);
            width: 100%;
            font-weight: 700;

            &:nth-child(1),
            &:nth-child(2) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                padding-bottom: 10px;
            }

            &__buttons {
                @include flex(row, center, flex-start, 7px);

                &__button {
                    padding: 7px 12px;
                    border: none;
                    font-weight: 600;
                    border-radius: 5px;
                    appearance: none;
                    color: black !important;

                    &:not(.active) {
                        opacity: .5;
                        cursor: pointer;
                        transition: all 300ms;

                        &:hover {
                            opacity: .8;
                        }
                    }
                }
            }
        }

        &__footer {
            @include flex(row, center, flex-end, 7px);
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            padding-top: 10px;
            width: 100%;
        }

        &__confirm {
            padding: 7px 12px;
            border: none;
            background: $colorPrimary;
            color: white;
            font-weight: 600;
            border-radius: 5px;
            cursor: pointer;
            transition: all 300ms;

            &:hover {
                background: darken($colorPrimary, 5%);
            }
        }
    }

    &--floating {
        @include flex(row, center, center);
        position: fixed;
        bottom: 15px;
        left: 15px;
        border: none;
        background: $colorPrimary;
        background-size: 200% 200%;
        color: white;
        font-size: 28px;
        box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.25);
        width: 47px;
        height: 47px;
        border-radius: 100px;
        cursor: pointer;
        transition: all 300ms;

        &.l,
        &.xl,
        &.xxl {
            left: 30px;
        }

        &:hover {
            filter: brightness(90%);
        }
    }
}