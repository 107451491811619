.footer {
    background: black;
    color: white;
    padding: 30px 15px 80px 15px;
    width: 100%;
    font-weight: 500;
    transition: all 300ms;

    &.l,
    &.xl,
    &.xxl {
        padding: 40px 30px 80px 30px;
    }

    &__top {
        @include flex(column, flex-start, flex-start, 10px);

        &__logo {
            height: 75px;
            width: 75px;

            &:hover {
                .footer__top__logo__image {
                    filter: brightness(90%);
                }
            }

            &__image {
                height: 100%;
                width: 100%;
                border-radius: 5px;
                transition: all 300ms;
            }
        }
    }

    &__links {
        @include flex(column, flex-start, flex-start, 30px);
        margin-top: 30px;

        &::before {
            content: "";
            background: white;
            height: 2px;
            width: 100%;
            border-radius: 100px;
            filter: opacity(0.5);
            transition: all 300ms;
        }

        &__block {
            @include flex(column, flex-start, flex-start, 10px);
            transition: all 300ms;
    
            &.xl,
            &.xxl {
                gap: 15px;
            }
    
            &__title {
                margin: 0;
                font-size: 20px;
            }
    
            &__items {
                @include flex(column, flex-start, flex-start, 10px);
                transition: all 300ms;
    
                &.l,
                &.xl,
                &.xxl {
                    @include flex(row, center, flex-start, 20px);
                }
    
                &.xl,
                &.xxl {
                    gap: 30px;
                }
    
                &__item {
                    position: relative;
                    color: white;
                    font-weight: 500;
                    text-decoration: none;
        
                    &,
                    &::after {
                        transition: all 300ms;
                    }
        
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        background-color: $colorPrimary;
                        width: 0;
                        height: 2px;
                        border-radius: 100px;
                    }
        
                    &:hover {
                        color: darken(white, 20%);
        
                        &::after {
                            width: 100%;
                        }
                    }

                    &--powered {
                        color: darken(white, 30%);

                        span {
                            font-weight: 600;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}