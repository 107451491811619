html {
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    overflow-x: hidden;
    background: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.no-scroll {
        overflow: hidden;
    }

    * {
        box-sizing: border-box;
    }
}

html,
body,
html *:not(svg),
body *:not(svg) {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

.app {
    overflow: hidden;
}