.header {
    @include flex(row, center, space-between, 20px);
    position: fixed;
    height: $headerHeight;
    background: black;
    z-index: 99;
    width: 100%;
    transition: all 300ms;

    &::after {
        content: "";
        position: fixed;
        top: $headerHeight;
        left: 0;
        height: 3px;
        width: 100%;
        background: linear-gradient(90deg, $colorPrimary, lighten($colorPrimary, 25%), $colorPrimary);
        box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.2);
        background-size: 200% 200%;
        animation: gradientAnimation 3s ease infinite alternate;
    }

    @keyframes gradientAnimation {
        0% {
            background-position: 0% 50%;
        } 100% {
            background-position: 100% 50%;
        }
    }

    &.xs,
    &.s,
    &.m {
        padding: 8px 15px;
    }

    &.l {
        padding: 8px 25px;
    }

    &.xl,
    &.xxl {
        padding: 8px 30px;
    }

    @keyframes opacity {
        from {
            filter: opacity(0);
        } to {
            filter: opacity(1);
        }
    }

    &__left {
        @include flex(row, center, flex-start, 15px);

        &.page--home {
            opacity: 0;
            transition: opacity 2.5s 1s ease-in-out;
        }

        &.finished {
            opacity: 1 !important;
        }

        &__link {
            @include flex(row, center, flex-start, 15px);
            text-decoration: none;
            padding: 0 10px 0 0;
            border-radius: 5px;
            transition: all 300ms;

            &:hover {
                .header__left__link__logo,
                .header__left__link__title {
                    filter: brightness(90%);
                }
            }

            &__logo,
            &__title {
                transition: all 300ms;
            }

            &__logo {
                width: 40px;
            }

            &__title {
                font-size: 20px;
                text-transform: uppercase;
                letter-spacing: 1px;
                background: linear-gradient(90deg, $colorPrimary, lighten($colorPrimary, 25%), $colorPrimary);
                background-size: 200%;
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                animation: gradientShift 3s ease infinite alternate;
            }
        }
    }

    &__right {
        @include flex(row, center, flex-start, 15px);

        &.page--home {
            opacity: 0;
            transition: opacity 2.5s 1s ease-in-out;
        }

        &.finished {
            opacity: 1 !important;
        }

        &__links {
            @include flex(row, center, flex-start, 40px);

            &__item {
                position: relative;
                color: white;
                font-weight: 600;
                text-decoration: none;
                white-space: nowrap;
                transition: all 300ms;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -5px;
                    height: 2px;
                    width: 0;
                    background: $colorPrimary;
                    border-radius: 10px;
                    transform-origin: left;
                    transition: all 300ms;
                }

                &:not(.active):hover {
                    &::after {
                        width: 100%;
                    }
                }

                &:not(.active) {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        }

        &__separator {
            height: 30px;
            width: 2px;
            background: rgba(255, 255, 255, 0.3);
            border: none;
            border-radius: 10px;

            &.alt {
                margin-right: 10px;
            }

            &.xs,
            &.s,
            &.m,
            &.l {
                margin-left: 10px;
            }

            &.xl,
            &.xxl {
                margin: 0 20px;
            }
        }

        &__networks {
            @include flex(row, center, flex-start, 15px);

            &__item {
                @include flex(row, center, center);
                color: white;
                filter: opacity(0.6);
                transition: all 300ms;

                &:hover {
                    filter: opacity(1);
                }

                &.instagram {
                    font-size: 25px;
                }

                &.facebook {
                    font-size: 20px;
                }
            }
        }

        &__language {
            @include flex(row, center, flex-start, 15px);
            border: none;
            background: none;
            padding: 0;
            font-weight: 600;
            font-size: 14px;
            color: white;
            cursor: pointer;
            transition: all 300ms;

            &:hover {
                filter: brightness(95%);
            }

            &__flag {
                height: 22px;
                width: 32px;
                border-radius: 5px;

                &.french {
                    filter: saturate(5);
                }

                &.english {
                    filter: saturate(2);
                }
            }
        }

        &__button {
            @include flex(column, center, center, 6px);
            position: relative;
            background: none;
            border: none;
            min-height: 30px;
            max-height: 30px;
            min-width: 30px;
            max-width: 30px;
            margin-left: 10px;
            cursor: pointer;
            padding: 0;
            border-radius: 5px;
            transition: all 300ms;

            &:hover {
                .header__right__button__bar {
                    background: $colorPrimary;
                }
            }

            &.visible {
                .header__right__button__bar {
                    width: 27px;
                    position: absolute;
                    margin-left: -2px;
                }

                .header__right__button__bar:nth-of-type(1) {
                    transform: rotate(45deg);
                }

                .header__right__button__bar:nth-of-type(2) {
                    transform: rotate(-45deg);
                }

                .header__right__button__bar:nth-of-type(3) {
                    filter: opacity(0);
                }
            }

            &__bar {
                height: 3px;
                width: 22px;
                background: white;
                border-radius: 10px;
                transition: all 500ms;
            }
        }
    }

    &__menu {
        @include flex(column, center, center);
        position: absolute;
        left: 0;
        top: calc($headerHeight + 3px);
        height: calc(100vh - $headerHeight - 3px);
        width: 100%;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 500ms ease-in-out;

        &:not(.header__menu--home) {
            top: calc($headerHeight + 3px + $breadcrumbHeight);
            height: calc(100vh - $headerHeight - 3px - $breadcrumbHeight);
        }

        &.visible {
            transform: scaleY(1);
        }

        &__links {
            @include flex(column, center, center, 23px);
        }

        &__bottom {
            @include flex(column, center, center, 30px);
            margin-top: 25px;

            .header__right__language {
                margin-top: -15px;
                padding: 15px;
                border-radius: calc(5px + (15px * 0.1));

                &:hover {
                    background: #525252;
                }
            }
        }

        &__background {
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.8);
            backdrop-filter: blur(30px);
            z-index: -1;
            cursor: pointer;
        }
    }
}