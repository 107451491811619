.useful {
    position: relative;
    width: 100%;
    margin-top: 20px;

    &__title {
        margin-bottom: 20px;
        font-size: 20px;
    }

    &__link {
        color: $colorPrimary;
        transition: all 300ms;

        &:hover {
            color: darken($colorPrimary, 10%);
        }
    }

    &__blocks {
        @include flex(column, flex-start, flex-start, 15px);

        &__block {
            @include flex(column, flex-start, flex-start);
            font-weight: 500;
            background: darken(white, 5%);
            padding: 15px;
            border-radius: 10px;
            width: 100%;

            &__title {
                font-size: 17px;
                margin-bottom: 10px;
            }

            &__body {
                @include flex(column, flex-start, flex-start, 10px);

                &__text {
                    &--title {
                        font-weight: 700;
                        margin-bottom: -10px;
                    }

                    &:has(span) {
                        @include flex(column, flex-start, flex-start);
                        color: black;
                        font-weight: 600;

                        span {
                            font-weight: 500;
                            color: lighten(black, 30%);
                        }
                    }
                }

                &__buttons {
                    @include flex(column, flex-start, flex-start, 5px);
                    margin: 10px 0;

                    &__button,
                    &__link {
                        @include flex(row, flex-start, flex-start);
                        text-align: left;
                        font-weight: 500;
                        color: $colorPrimary;
                        transition: all 300ms;

                        &:hover {
                            color: darken($colorPrimary, 20%);
                        }
                    }

                    &__button {
                        border: none;
                        background: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}