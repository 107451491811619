.ribbon {
    position: absolute;
    z-index: 9;
    right: 10px;
    top: -46px;
    font-size: 12px;
    font-weight: bold;
    color: white;

    &__text {
        position: relative;
        padding: 8px 15px;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            height: 100%;
            width: 100%;
            border-radius: 7px;
            background: linear-gradient(to right, $colorPrimary 20%, $colorPrimary 40%, lighten($colorPrimary, 20%) 60%, $colorPrimary 80%);
            background-size: 200% auto;
            color: white;
            box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
            -webkit-text-fill-color: transparent;
            animation: shine 2s linear infinite;
        }
    }
}

@keyframes shine {
    50% {
        background-position: initial;
    }
    100% {
        background-position: 200% center;
    }
}