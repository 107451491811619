.service {
    &__pagination {
        @include flex(row, center, space-between, 5px);
        width: 100%;
        margin-top: -70px;
        transition: all 300ms;

        &.xs {
            @include flex(column, flex-start, flex-start, 5px);

            .button {
                &,
                &:hover {
                    width: 100%;
                }

                &:first-of-type {
                    @include flex(row, center, flex-start, 10px);
                }

                &:last-of-type {
                    @include flex(row, center, flex-end, 10px);
                }
            }
        }
    }
}