.shop {
    &__product {
        @include flex(column, center, flex-start);
        width: 100%;

        &__image {
            @include flex(row, center, center);
            width: 100%;
            height: 500px;
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            transition: all 300ms;

            &.xs,
            &.s {
                border-radius: 10px;
            }

            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }

        &__bottom {
            @include flex(column, flex-start, flex-start, 10px);
            width: 100%;
            margin-top: 15px;
            transition: all 300ms;

            &.m,
            &.l,
            &.xl,
            &.xxl {
                border: 2px solid darken(white, 5%);
                margin-top: 0;
                padding: 15px;
                border-radius: 0 0 10px 10px;
            }
        }

        &__first-line,
        &__second-line {
            @include flex(row, center, space-between, 10px);
            width: 100%;
        }

        &__name,
        &__price {
            font-size: 20px;
            font-weight: 700;
            margin: 0;
        }

        &__price {
            color: $colorPrimary;
        }

        &__colors {
            @include flex(row, center, center, 12px);
            margin-left: 4px;

            &__item {
                position: relative;
                width: 18px;
                height: 18px;
                border-radius: 100px;

                &::after {
                    content: "";
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    width: 22px;
                    height: 22px;
                    border: 2px solid darken(white, 15%);
                    border-radius: 100px;
                }

                &.black {
                    background: black;
                }

                &.pink {
                    background: linear-gradient(45deg, $colorPrimary, lighten($colorPrimary, 20%));
                }
            }
        }

        &__sizes {
            @include flex(row, center, flex-start, 5px);

            &__item {
                font-weight: 500;
                border: 2px solid darken(white, 15%);
                padding: 2px 8px;
                border-radius: 5px;
            }
        }
    }
}