.bicyclette {
    &__networks {
        @include flex(row, center, flex-start, 10px);
        flex-wrap: wrap;
    }

    &__gallery {
        @include flex(column, flex-start, flex-start, 10px);
        transition: all 300ms;

        &--service {
            @include flex(row, flex-start, space-between, 5px);
        }

        &--shop {
            gap: 15px !important;

            &.xs,
            &.s {
                gap: 50px !important;
                width: 100%;
            }
        }

        &.m,
        &.l,
        &.xl,
        &.xxl {
            @include flex(row, flex-start, space-between, 10px);
        }

        &__list {
            @include flex(column, flex-start, flex-start, 10px);
            width: 100%;
            transition: all 300ms;

            &--service {
                @include flex(column, flex-start, flex-start, 5px);
                width: 50%;
            }

            &--shop {
                @include flex(column, flex-start, flex-start, 5px);
                gap: 15px;

                &.xs,
                &.s {
                    gap: 50px;
                }
            }

            &.m,
            &.l {
                width: 50%;
            }

            &.xl,
            &.xxl {
                width: 33.3%;
            }

            &__image {
                border-radius: 5px;
                width: 100%;
                cursor: pointer;
                transition: all 300ms;

                &:hover {
                    filter: brightness(85%);
                }

                &:active {
                    filter: brightness(70%);
                }
            }
        }

        &__noresult {
            font-size: 15px;
            font-weight: 500;
        }
    }
}