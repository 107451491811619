.contact {
    position: relative;
    width: 100%;
    margin-top: 20px;

    &__title {
        margin: 0;
        font-size: 20px;
    }

    &__disclaimer {
        margin: 10px 0 0 0;
        font-weight: 500;

        span {
            font-weight: 600;
            color: $colorPrimary;
        }
    }

    &__error {
        margin-top: 5px;
        font-weight: 500;
        color: rgb(255, 0, 80);
    }

    &__form {
        @include flex(column, flex-start, flex-start, 15px);
        margin-top: 30px;

        &__row {
            @include flex(row, flex-start, space-between, 15px);
            width: 100%;

            &.xs {
                @include flex(column, flex-start, flex-start, 15px);
            }
        }

        &__field {
            @include flex(column, flex-start, flex-start, 5px);
            position: relative;
            width: 100%;

            &__label {
                font-weight: 500;
            }

            &__input,
            &__textarea,
            &__select {
                width: 100%;
                font-size: 14px;
                border-radius: 10px;
                border: 1px solid darken(white, 20%);
                padding: 10px 15px;
            }

            &__select {
                appearance: none !important;
                color: black !important;
                background: white !important;
                width: 100%;
                cursor: pointer;
            }

            svg {
                position: absolute;
                right: 20px;
                bottom: 15px;
                transform: rotate(90deg);
                color: rgb(90, 90, 90);
            }

            &__input {
                &.full-name {
                    text-transform: capitalize;
                }
            }

            &__textarea {
                resize: vertical;
                min-height: 100px;
                max-height: 300px;
            }
        }

        &__captcha {
            margin-top: 5px;
        }
    }

    &__result {
        margin: 10px 0 0 0;

        &__error,
        &__success {
            margin: 0;
            font-size: 15px;
            padding: 15px;
            border-radius: 10px;
            font-weight: 500;
        }

        &__error {
            background: rgb(255, 0, 85);
            color: white;
        }

        &__success {
            background: rgb(0, 255, 149);
        }

        &__disclaimer {
            font-size: 15px;
            font-weight: 500;
            margin: 0 0 10px 0;
        }
    }

    &__details {
        margin-top: 70px;

        &__info {
            @include flex(column, flex-start, flex-start, 10px);
            margin-top: 20px;

            &__item {
                @include flex(row, center, flex-start, 15px);
                font-size: 15px;
                font-weight: 500;
                color: black;
                text-decoration: none;
                transition: all 300ms;

                &:hover {
                    filter: brightness(90%);
                }

                &__icon {
                    @include flex(row, center, center);
                    padding: 10px;
                    border-radius: 100px;
                    background: $colorPrimary;
                    color: white;
                }
            }
        }
    }

    &__loading {
        @include flex(column, flex-start, flex-start, 15px);
        margin-top: 20px;
        font-size: 15px;
        font-weight: 500;

        &__spinner {
            @include flex(row, center, flex-start, 15px);
            font-size: 18px;
            color: $colorPrimary;
            font-weight: 600;

            svg {
                color: $colorPrimary;
                font-size: 30px;
                transform-origin: center;
                animation: rotate 1s linear infinite;
            }
        }

        @keyframes rotate {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__progress {
        transform: rotate(-90deg);
        overflow: visible;
        margin-left: -5px;

        &__background {
            fill: none;
            stroke: #e6e6e6;
        }

        &__circle {
            fill: none;
            stroke: $colorPrimary;
            transform-origin: center;
            transition: all 300ms;
        }

        &__text {
            transform: rotate(90deg);
            font-size: 18px;
            color: $colorPrimary;
            font-weight: 600;
        }
    }

    &__cookies {
        @include flex(column, flex-start, flex-start);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0.5) 100%);

        &__disclaimer {
            font-weight: 500;
            margin-bottom: 20px;

            span {
                font-weight: 600;
                color: $colorPrimary;
            }
        }
    }
}