.carousel {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 80px;
    overflow: hidden;
    width: 100%;
    max-width: 1500px;
    transition: all 300ms;

    &.xxl {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 8;
            background: linear-gradient(to left, transparent 0%, white 100%);
            height: 100%;
            width: 100px;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(to right, transparent 0%, white 100%);
            height: 100%;
            width: 100px;
        }
    }

    &__container {
        @include flex(row, flex-start, flex-start, 20px);
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 50px;
        cursor: grab;
        scrollbar-width: none;
        transition: all 300ms;

        &.snap-type {
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
        }

        &.xl,
        &.xxl {
            gap: 30px;
        }

        &__item {
            @include flex(column, center, center, 30px);
            scroll-snap-align: center;
            transition: all 300ms;

            &.xs,
            &.s {
                .carousel__container__item__title,
                .carousel__container__item__text {
                    padding: 0 30px;
                }
            }

            &.xs,
            &.s,
            &.m {
                &:first-of-type {
                    margin-left: 20px;
                }
    
                &:last-of-type {
                    margin-right: 20px;
                }
            }

            &.l,
            &.xl {
                &:first-of-type {
                    margin-left: 30px;
                }
    
                &:last-of-type {
                    margin-right: 30px;
                }
            }

            &.m,
            &.l,
            &.xl {
                min-width: calc(100% - 250px);
                max-width: calc(100% - 250px);
            }

            &.xs {
                min-width: calc(100% - 100px);
                max-width: calc(100% - 100px);
            }

            &.s {
                min-width: calc(100% - 150px);
                max-width: calc(100% - 150px);
            }

            &.m {
                .carousel__container__item__title,
                .carousel__container__item__text {
                    padding: 0 50px;
                }
            }

            &.l {
                .carousel__container__item__title,
                .carousel__container__item__text {
                    min-width: 600px;
                    max-width: 600px;
                }
            }

            &.xl {
                .carousel__container__item__title,
                .carousel__container__item__text {
                    min-width: 800px;
                    max-width: 800px;
                }
            }

            &.xxl {
                min-width: 1140px;
                max-width: 1140px;

                &:first-of-type {
                    margin-left: calc(1500px - 1140px);
                }
    
                &:last-of-type {
                    margin-right: calc(1500px - 1140px);
                }

                .carousel__container__item__picture {
                    height: 350px;
                }

                .carousel__container__item__title,
                .carousel__container__item__text {
                    min-width: 800px;
                    max-width: 800px;
                }
            }

            &__picture {
                height: 300px;
                width: 100%;
                border-radius: 20px;
                object-fit: cover;
                user-select: none;
            }

            &__title,
            &__text {
                margin: 0;
                text-align: center;
                transition: all 300ms;
            }

            &__title {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
}